.passport-upload-box {
    margin-bottom: 20px;
    width: 100% ;
}

.upload-box {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
    width: 100% !important;
}

.upload-box:hover {
    border-color: #aaa;
}

.upload-box img {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
}

#upload-photo {
    display: none;
}
